import { injectGlobal } from 'styled-components'
import font from '../fonts/FrancoisOne-Regular.ttf'

injectGlobal`
  html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  body {
    margin: 0;
    position: absolute;
    background-color: #178ec8;
    font-size: 16px;
  }

  @font-face {
    font-family: 'FrancoisOne';
    font-style: normal;
    font-weight: normal;
    src: url('${font}');
  } 
`
