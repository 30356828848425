import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import SocialIcon from '../components/SocialIcon'
import logo from '../images/logo.svg'
import instagram from '../images/social_gram.svg'
import facebook from '../images/social_face.svg'
import twitter from '../images/social_tweet.svg'

const title = "Alexander Foundation"

const NavWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2em 3em;
    box-sizing: border-box;
    z-index: 999;

    @media (max-width: 900px) {
        padding: 1em;
    }
`

const Logo = styled.img`
  height: 95px;
  width: auto;
`

const SocialBox = styled.div`
  height: 30px;

  ${SocialIcon} {
    display: inline-block;
    height: 30px;
    width: 30px;
  }

   ${SocialIcon}:not(:last-child) {
     padding-right: 10px;
   }
`

const Component = () => (
    <>
        <Helmet
        title={title}
        meta={[
            { name: 'description', content: 'We are a charity that provides support and financial aid to families with children affected by neurological and development issues, in the Hertfordshire and Essex community.' },
            { name: 'keywords', content: 'Alexander Trust, charity' },
            { name: 'viewport', content:"initial-scale=1.0, width=device-width"
        }

        ]}
        >
        <html lang="en" />
        </Helmet>
        <NavWrapper>
            <Logo src={logo} />
            <SocialBox>
                <SocialIcon image={facebook} link="https://www.facebook.com/alexanderfoundationUK/" alt="Facebook icon"/>
                <SocialIcon image={twitter} link="http://www.twitter.com/afcharityuk" alt="Twitter icon"/>
                <SocialIcon image={instagram} link="http://www.instagram.com/thealexanderfoundation" alt="Instagram icon"/>
            </SocialBox>
        </NavWrapper>
    </>
)

export default Component