import React from 'react'
import styled from 'styled-components'

const Component = ({className, image, link, alt}) => (
    <a className={className} href={link}>
        <img src={image} alt={alt}></img>
    </a>
)

export default styled(Component)`
    height: 30px;
    width: 30px;
    transition: opacity .5s ease-out;
    opacity: 1;

    img {
        height: 100%;
        width: 100%;
    }

    &:hover {
        opacity: .5;
    }
`
