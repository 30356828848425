import React from 'react'
import styled from 'styled-components'

import '../components/globalStyles'
import Navigation from '../components/Navigation'
import Balloon from '../components/Balloon'

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;

  &>div {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`

const Balloons = styled.div`
  width: 50%;
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-end;
  overflow: hidden;

  img {
    display: inline-block;
    margin-right: -50px;
  }

  img:nth-child(1) {
    height: 400px;
    animation: float 6s ease-in-out infinite;
  }

  img:nth-child(2) {
    height: 500px;
    margin-right: -75px;
    animation: float 7s ease-in-out infinite;
  }

  img:nth-child(3) {
    height: 600px;
    margin-right: -75px;
    animation: float 10s ease-in-out infinite;
  }

  @media (max-width: 900px) {
    width: 100vw;
    height: 100vh;
    position: relative;
    top: 0;
    left: 0;

    img:nth-child(1) {
      position: absolute;
      height: 500px;
      left: 30%;
      top: 50px;
      animation: float 6s ease-in-out infinite;
    }
  
    img:nth-child(2) {
      position: absolute;
      height: 400px;
      left: 0;
      top: 300px;
      animation: float 6s ease-in-out infinite;
    }
  
    img:nth-child(3) {
      position: absolute;
      height: 300px;
      left: 50%;
      top: 400px;
      animation: float 6s ease-in-out infinite;
    }
  }


  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-25px);
    }
    100% {
      transform: translatey(0px);
    }
  }
`

const Blurb = styled.div`
  display: inline-flex;
  width: 50%;
  align-content: center;
  padding-left: 110px;
  box-sizing: border-box;

  p {
      display: inline-block;
      font-size: 4em;
      color: white;
      font-family: 'FrancoisOne', sans-serif;
      text-transform: uppercase;
      line-height: 1em;
      width: 4em;
  }

  @media (max-width: 900px) {
    position: absolute;
    left: 1em;
    padding-left: 0;
  }
`

const IndexPage = ({className}) => (
  <div className={className}>
    <Navigation/>
    <Wrapper>
      <div>
        <Balloons>
          <Balloon/>
          <Balloon/>
          <Balloon/>
        </Balloons>
        <Blurb>
          <p>Website Launching Soon</p>
        </Blurb>
      </div>
    </Wrapper>
  </div>
)

export default styled(IndexPage)`
  position: absolute;
  width: 100%;
  height: 100%;
`
